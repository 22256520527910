/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {PlaceFilterDistricts} from '../../../../app/pages/place/filter/Districts'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: any = useSelector<RootState>(({auth}) => auth.user)

  return (
    <>
      <AsideMenuItem to='/dashboard' title={intl.formatMessage({id: 'MENU.DASHBOARD'})} />
      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2' />
      </div>
      <AsideMenuItemWithSub to='/user' title='사용자'>
        <AsideMenuItem to='/user/list' title='리스트' hasBullet={true} />
        <AsideMenuItem to='/user/group' title='그룹' hasBullet={true} />
        {/* <AsideMenuItem to='/user/tracking' title='트래킹' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2' />
      </div>
      <AsideMenuItemWithSub to='/team' title='팀'>
        <AsideMenuItem to='/team/dashboard' title='현황' hasBullet={true} />
        <AsideMenuItem to='/team/list' title='리스트' hasBullet={true} />
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2' />
      </div>
      <AsideMenuItemWithSub to='/booking' title='예약'>
        <AsideMenuItem to='/booking/place' title='장소' hasBullet={true} />
        <AsideMenuItem to='/booking/place/new' title='장소(임시)' hasBullet={true} />
        <AsideMenuItem to='/booking/refund' title='환불' hasBullet={true} />
        <AsideMenuItem to='/booking/message' title='메시지' hasBullet={true} />
        <AsideMenuItem to='/booking/today' title='오늘의 장소' hasBullet={true} />
        <AsideMenuItem to='/booking/feedback' title='후기' hasBullet={true} />
        <AsideMenuItem to='/booking/project' title='분류' hasBullet={true} />
        <AsideMenuItem to='/booking/genre' title='장르 태그' hasBullet={true} />
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2' />
      </div>
      <AsideMenuItemWithSub to='/advertise' title='광고상품'>
        <AsideMenuItem to='/advertise/dashboard' title='광고 현황' hasBullet={true} />
        <AsideMenuItem to='/advertise/network/schedule' title='네트워크 광고 일정' hasBullet={true} />
        <AsideMenuItem to='/advertise/network/place/old' title='네트워크 광고(구)' hasBullet={true} />
        <AsideMenuItem to='/advertise/network/plus/place/old' title='네트워크 광고 PLUS(구)' hasBullet={true} />
        <AsideMenuItem to='/advertise/network/pro/place/old' title='네트워크 광고 PRO(구)' hasBullet={true} />
        <AsideMenuItem to='/advertise/category/place/old' title='카테고리 상단 광고(구)' hasBullet={true} />
        <AsideMenuItem to='/advertise/category/place/thumbnail' title='장소 광고 썸네일' hasBullet={true} />
        <AsideMenuItem to='/advertise/main/banner/form' title='메인 배너 광고 신청서' hasBullet={true} />
        <AsideMenuItem to='/advertise/main/banner' title='메인 배너 광고' hasBullet={true} />
        <AsideMenuItem to='/advertise/btob/banner/guest' title='게스트향 배너 광고' hasBullet={true} />
        <AsideMenuItem to='/advertise/btob/banner/host' title='호스트향 배너 광고' hasBullet={true} />
        <AsideMenuItem to='/advertise/cancel' title='취소/환불' hasBullet={true} />
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2' />
      </div>
      <AsideMenuItemWithSub to='/qna' title='문의'>
        <AsideMenuItem to='/qna/place' title='장소 문의' hasBullet={true} />
        <AsideMenuItem to='/qna' title='문의' hasBullet={true} />
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2' />
      </div>
      <AsideMenuItemWithSub to='/grey' title='예약 모니터링'>
        <AsideMenuItem to='/grey' title='그레이 설정' hasBullet={true} />
        <AsideMenuItem to='/grey/guest/place/qna' title='게스트 - 장소문의' hasBullet={true} />
        <AsideMenuItem to='/grey/guest/booking' title='게스트 - 예약신청' hasBullet={true} />
        <AsideMenuItem to='/grey/guest/message' title='게스트 - 예약문의' hasBullet={true} />
        <AsideMenuItem to='/grey/guest/userchange' title='게스트 - 프로필' hasBullet={true} />
        <AsideMenuItem to='/grey/guest/bookingcancel' title='게스트 - 예약취소' hasBullet={true} />
        <AsideMenuItem to='/grey/host/place' title='호스트 - 장소소개' hasBullet={true} />
        <AsideMenuItem to='/grey/host/answer' title='호스트 - 장소문의' hasBullet={true} />
        <AsideMenuItem to='/grey/host/message' title='호스트 - 예약문의' hasBullet={true} />
        <AsideMenuItem to='/grey/host/hostchange' title='호스트 - 프로필' hasBullet={true} />
        <AsideMenuItem to='/grey/host/bookingcancel' title='호스트 - 예약취소' hasBullet={true} />
        <AsideMenuItem to='/grey/host/automessage' title='호스트 - 자동안내메시지' hasBullet={true} />
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2' />
      </div>
      <AsideMenuItemWithSub to='/feed' title='피드 모니터링'>
        <AsideMenuItem to='/feed/setting' title='그레이 설정' hasBullet={true} />
        <AsideMenuItem to='/feed/place/grey' title='장소 피드_그레이' hasBullet={true} />
        <AsideMenuItem to='/feed' title='장소 피드_장소 소식' hasBullet={true} />
        <AsideMenuItem to='/feed/feedback' title='장소 피드_장소 후기' hasBullet={true} />
        <AsideMenuItem to='/feed/watch' title='장소 피드_콘텐츠 속 장소' hasBullet={true} />
        <AsideMenuItem to='/feed/portfolio/grey' title='게스트 피드_그레이' hasBullet={true} />
        <AsideMenuItem to='/feed/portfolio' title='게스트 피드_포트폴리오' hasBullet={true} />
      </AsideMenuItemWithSub>

      {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1 || (user.admin >> 8) % 2 === 1) && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-2 pb-2' />
          </div>
          <AsideMenuItemWithSub to='/pay' title='정산'>
            <AsideMenuItem to='/pay/host/cash/view' title='호스트 정산(캐시 출금_조회용)' hasBullet={true} />
            <AsideMenuItem to='/pay/host/popbill/member' title='호스트 정산 (팝빌 연동 회원 조회)' hasBullet={true} />,
            {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1) && [
              <AsideMenuItem to='/pay/host' title='호스트 정산' hasBullet={true} />,
              <AsideMenuItem to='/pay/host/cash' title='호스트 정산 (캐시 출금)' hasBullet={true} />,
              <AsideMenuItem to='/pay/bank' title='잔액 조회' hasBullet={true} />,
              <AsideMenuItem to='/pay/host/report' title='정산 결산표(경남은행)' hasBullet={true} />,
            ]}
            {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1 || (user.admin >> 8) % 2 === 1) && [<AsideMenuItem to='/pay/host/report2' title='정산 결산표(예약-old)' hasBullet={true} />]}
            {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1 || (user.admin >> 8) % 2 === 1) && [<AsideMenuItem to='/pay/host/report5' title='정산 결산표(예약-new)' hasBullet={true} />]}
            {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1 || (user.admin >> 8) % 2 === 1) && [<AsideMenuItem to='/pay/host/report6' title='정산 결산표(예약(촬영일)-new)' hasBullet={true} />]}
            {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1 || (user.admin >> 8) % 2 === 1) && [<AsideMenuItem to='/pay/host/report4' title='지불 결산표(사업자)' hasBullet={true} />]}
            {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1 || (user.admin >> 8) % 2 === 1) && [<AsideMenuItem to='/pay/host/report3' title='지불 결산표(개인)' hasBullet={true} />]}
            {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1 || (user.admin >> 8) % 2 === 1) && [<AsideMenuItem to='/pay/point' title='포인트' hasBullet={true} />]}
            {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1 || (user.admin >> 8) % 2 === 1) && [<AsideMenuItem to='/pay/point/withdraw' title='포인트 출금 요청' hasBullet={true} />]}
            {(user.admin >> 10) % 2 === 1 && []}
          </AsideMenuItemWithSub>
        </>
      )}

      {(user.admin >> 10) % 2 !== 1 && (user.admin >> 9) % 2 !== 1 && (user.admin >> 8) % 2 !== 1 && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-2 pb-2' />
          </div>
          <AsideMenuItemWithSub to='/pay' title='정산'>
            <AsideMenuItem to='/pay/host/cash/view' title='호스트 정산(캐시 출금_조회용)' hasBullet={true} />
          </AsideMenuItemWithSub>
        </>
      )}

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2' />
      </div>
      <AsideMenuItemWithSub to='/place' title='장소'>
        <AsideMenuItem to='/place' title='리스트' hasBullet={true} />
        <AsideMenuItem to='/place/filter/regions' title='필터 지역 관리' hasBullet={true} />
        <AsideMenuItem to='/place/filter/districts' title='필터 지역 > 구/동 관리' hasBullet={true} />
        <AsideMenuItem to='/place/category' title='카테고리' hasBullet={true} />
        <AsideMenuItem to='/place/category/filter/group' title='카테고리 필터 그룹 관리' hasBullet={true} />
        <AsideMenuItem to='/place/category/list' title='카테고리 필터 관리' hasBullet={true} />
        <AsideMenuItem to='/place/search_tag' title='관리자 태그' hasBullet={true} />
        <AsideMenuItem to='/place/count/history/h' title='자사 일자별 장소 개수' hasBullet={true} />
        <AsideMenuItem to='/place/count' title='경쟁사 장소 개수' hasBullet={true} />
        <AsideMenuItem to='/place/count/history' title='경쟁사 일자별 장소 개수' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/*<div className='menu-item'>*/}
      {/*  <div className='menu-content pt-2 pb-2' />*/}
      {/*</div>*/}
      {/*<AsideMenuItemWithSub*/}
      {/*  to='/x'*/}
      {/*  title='프로덕션'>*/}
      {/*  <AsideMenuItem to='/x' title='X' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2' />
      </div>
      <AsideMenuItemWithSub to='/marketing' title='마케팅'>
        <AsideMenuItem to='/marketing/recommend/category' title='홈 프로모션' hasBullet={true} />
        <AsideMenuItem to='/marketing/curation' title='큐레이션' hasBullet={true} />
        <AsideMenuItem to='/marketing/magazine' title='매거진' hasBullet={true} />
        <AsideMenuItem to='/marketing/coupon' title='쿠폰' hasBullet={true} />
        <AsideMenuItem to='/marketing/event' title='이벤트' hasBullet={true} />
        <AsideMenuItem to='/marketing/main/banner/image' title='메인배너 (full-image)' hasBullet={true} />
        <AsideMenuItem to='/marketing/main/banner' title='메인배너' hasBullet={true} />
        <AsideMenuItem to='/marketing/btob/banner/guest' title='게스트향 배너' hasBullet={true} />
        <AsideMenuItem to='/marketing/btob/banner/host' title='호스트향 배너' hasBullet={true} />
        <AsideMenuItem to='/marketing/interview' title='아워플레이스 소식' hasBullet={true} />
        <AsideMenuItem to='/marketing/place/commentary' title='장소 코멘터리' hasBullet={true} />
        <AsideMenuItem to='/marketing/creative' title='크리에이티브 분야' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/*<div className='menu-item'>*/}
      {/*  <div className='menu-content pt-2 pb-2' />*/}
      {/*</div>*/}
      {/*<AsideMenuItemWithSub*/}
      {/*  to='/x'*/}
      {/*  title='기프티쇼'>*/}
      {/*  <AsideMenuItem to='/x' title='X' hasBullet={true} />*/}
      {/*</AsideMenuItemWithSub>*/}

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2' />
      </div>
      <AsideMenuItemWithSub to='/media' title='이미지&영상'>
        <AsideMenuItem to='/media/image/feedback' title='후기 이미지' hasBullet={true} />
        <AsideMenuItem to='/media/image/feedback/view' title='후기 이미지 뷰' hasBullet={true} />
        <AsideMenuItem to='/media/image/place' title='장소 이미지' hasBullet={true} />
        <AsideMenuItem to='/media/video/place' title='장소 영상' hasBullet={true} />
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2' />
      </div>
      <AsideMenuItemWithSub to='/search' title='검색'>
        <AsideMenuItem to='/search/keyword' title='검색어' hasBullet={true} />
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2' />
      </div>
      <AsideMenuItemWithSub to='/statistics' title='통계'>
        <AsideMenuItemWithSub to='/statistics/booking' title='예약'>
          <AsideMenuItem to='/statistics/booking/day' title='촬영일' hasBullet={true} />
          <AsideMenuItem to='/statistics/booking/payment/user' title='결제 사용자' hasBullet={true} />
          <AsideMenuItem to='/statistics/booking/user' title='사용자' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/statistics/device' title='디바이스'>
          <AsideMenuItem to='/statistics/device/booking' title='예약' hasBullet={true} />
          <AsideMenuItem to='/statistics/device/payment' title='결제' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/statistics/place' title='장소'>
          <AsideMenuItem to='/statistics/place/view/day' title='일자별 뷰수' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/statistics/project' title='프로젝트'>
          <AsideMenuItem to='/statistics/project/type' title='분류' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2' />
      </div>
      <AsideMenuItemWithSub to='/log' title='로그'>
        <AsideMenuItem to='/log/alimtalk' title='알림톡' hasBullet={true} />
        <AsideMenuItem to='/log/kcp' title='KCP' hasBullet={true} />
        <AsideMenuItem to='/log/server' title='서버' hasBullet={true} />
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2' />
      </div>
      <AsideMenuItemWithSub to='/setting' title='설정'>
        <AsideMenuItem to='/setting' title='설정' hasBullet={true} />
        <AsideMenuItem to='/setting/alimtalk' title='알림톡' hasBullet={true} />
        <AsideMenuItem to='/setting/holiday' title='휴일 달력' hasBullet={true} />
        <AsideMenuItem to='/setting/agree' title='약관' hasBullet={true} />
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2' />
      </div>
      <AsideMenuItemWithSub to='/monitoring' title='모니터링'>
        <AsideMenuItem to='/monitoring' title='모니터링' hasBullet={true} />
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2' />
      </div>
      <AsideMenuItemWithSub to='/pending' title='보류'>
        <AsideMenuItem to='/advertise/network/place' title='네트워크 광고(작업중)' hasBullet={true} />
        <AsideMenuItem to='/advertise/network/plus/place' title='네트워크 광고 PLUS(작업중)' hasBullet={true} />
        <AsideMenuItem to='/advertise/network/pro/place' title='네트워크 광고 PRO(작업중)' hasBullet={true} />
        <AsideMenuItem to='/advertise/category/place' title='카테고리 상단 광고(작업중)' hasBullet={true} />
        <AsideMenuItem to='/marketing/concept/category' title='컨셉 카테고리' hasBullet={true} />
        <AsideMenuItem to='/marketing/banner' title='마케팅 배너' hasBullet={true} />
        <AsideMenuItem to='/marketing/benefits' title='혜택 안내 배너' hasBullet={true} />
        <AsideMenuItem to='/marketing/hourplace/shorts' title='아워플레이스 쇼츠' hasBullet={true} />
        <AsideMenuItem to='/marketing/event/new' title='이벤트 페이지' hasBullet={true} />
      </AsideMenuItemWithSub>

      <div className={'menu-item'}>
        <div className='menu-content pt-2 pb-2'>
          <p style={{textAlign: 'right'}}>version : {process.env.REACT_APP_VERSION}</p>
        </div>
      </div>
    </>
  )
}
