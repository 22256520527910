import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'

import {useSelector} from 'react-redux'
import {RootState} from '../../setup'

import {Dashboard} from '../pages/dashboard'
import {Revenue} from '../pages/dashboard/Revenue'

import {UserTracking} from '../pages/user/tracking'
import {UserList} from '../pages/user/list'
import {UserDetail} from '../pages/user/Detail'
import {QnA} from '../pages/qna'
import {QnAPlace} from '../pages/qna/place'
import {UserGroup} from '../pages/user/group'
import {UserGroupDetail} from '../pages/user/group/Detail'

import {TeamDashboard} from '../pages/team/TeamDashboard'
import {TeamList} from '../pages/team/list'
import {TeamDetail} from '../pages/team/Detail'

import {BookingPlace} from '../pages/booking/place'
import {BookingPlaceDetail} from '../pages/booking/place/Detail'
import {BookingPlaceCancel} from '../pages/booking/place/Cancel'
import {BookingPlacePayment} from '../pages/booking/place/Payment'
import {BookingPlaceTeamPayment} from '../pages/booking/place/TeamPayment'
import {BookingPlaceEditRefund} from '../pages/booking/place/EditRefund'
import {BookingPlaceEditInfo} from '../pages/booking/place/EditInfo'
import {BookingPlaceEditTime} from '../pages/booking/place/EditTime'
import {BookingPlaceAdditional} from '../pages/booking/place/Additional'
import {BookingFeedback} from '../pages/booking/feedback'
import {BookingToday} from '../pages/booking/today'
import {BookingRefund} from '../pages/booking/refund'
import {BookingProject} from '../pages/booking/project'
import {BookingMessage} from '../pages/booking/message'
import {BookingMessageDetail} from '../pages/booking/message/Detail'

import {AdvertiseDashboard} from '../pages/advertise/Dashboard'

import {AdvertiseNetworkScheduleAdd} from '../pages/advertise/network/schedule/Add'
import {AdvertiseNetworkScheduleEdit} from '../pages/advertise/network/schedule/Edit'
import {AdvertiseNetworkScheduleIndex} from '../pages/advertise/network/schedule/Index'

import {AdvertiseNetworkPlaceAdd} from '../pages/advertise/network/place/Add'
import {AdvertiseNetworkPlaceDetail} from '../pages/advertise/network/place/Detail'
import {AdvertiseNetworkPlaceIndex} from '../pages/advertise/network/place/Index'

import {AdvertiseNetworkPlusPlaceAdd} from '../pages/advertise/network/place/plus/Add'
import {AdvertiseNetworkPlusPlaceDetail} from '../pages/advertise/network/place/plus/Detail'
import {AdvertiseNetworkPlusPlaceIndex} from '../pages/advertise/network/place/plus/Index'

import {AdvertiseNetworkProPlaceAdd} from '../pages/advertise/network/place/pro/Add'
import {AdvertiseNetworkProPlaceDetail} from '../pages/advertise/network/place/pro/Detail'
import {AdvertiseNetworkProPlaceIndex} from '../pages/advertise/network/place/pro/Index'

import {AdvertiseCategoryPlaceDetail} from '../pages/advertise/category/place/Detail'
import {AdvertiseCategoryPlaceIndex} from '../pages/advertise/category/place/Index'
import {AdvertiseCategoryPlaceThumbnail} from '../pages/advertise/category/place/Thumbnail'

import {AdvertiseCancel} from '../pages/advertise/Cancel'
import {AdvertiseRefund} from '../pages/advertise/Refund'

import {Grey} from '../pages/grey'
import {GreyHost} from '../pages/grey/host'
import {GreyGuest} from '../pages/grey/guest'

import {MediaImageFeedback} from '../pages/media/image/feedback'
import {MediaImagePlace} from '../pages/media/image/place'
import {MediaVideoPlace} from '../pages/media/video/place'

import {Place} from '../pages/place'
import {PlaceDetail} from '../pages/place/Detail'
import {PlaceEdit} from '../pages/place/Edit'
import {PriceEdit} from '../pages/place/PriceEdit'
import {PlaceCategory} from '../pages/place/category'
import {PlaceBooking} from '../pages/place/Booking'

import {SearchKeyword} from '../pages/search/keyword'

import {PayHost} from '../pages/pay/host'
import {PayHostEdit} from '../pages/pay/host/PayHostEdit'
import {PayHostReport} from '../pages/pay/host/Report'
import {PayHostReport2} from '../pages/pay/host/Report2'
import {PayHostReport3} from '../pages/pay/host/Report3'
import {PayBank} from '../pages/pay/bank'
import {PayBankRemit} from '../pages/pay/bank/Remit'
import {PlaceSearchTag} from '../pages/place/SearchTag'
import {PayHostCash} from '../pages/pay/host/cash'
import {PayCashHostEdit} from '../pages/pay/host/cash/PayCashHostEdit'
import {PayHostCashView} from '../pages/pay/host/cash/view'

import {LogServer} from '../pages/log/server'
import {LogAlimtalk} from '../pages/log/alimtalk'
import {LogKCP} from '../pages/log/kcp'

import MainCategory from '../pages/marketing/curation/Curation'
import {MarketingCuration} from '../pages/marketing/curation'
import {MarketingCoupon} from '../pages/marketing/coupon'
import {MarketingCouponDetail} from '../pages/marketing/coupon/Detail'
import {MarketingMagazine} from '../pages/marketing/magazine'
import {MarketingEvent} from '../pages/marketing/event'
import {MarketingEventEditor} from '../pages/marketing/event/Editor'
import MagazineEditor from '../pages/marketing/magazine/MagazineEditor'

import RecommendCategory from '../pages/marketing/recommend/category'
import RecommendCategoryEditor from '../pages/marketing/recommend/category/RecommendCategoryEditor'

import StatisticsProjectType from '../pages/statistics/project/type'
import StatisticsBookingDay from '../pages/statistics/booking/day'
import StatisticsBookingPaymentUser from '../pages/statistics/booking/payment/user'
import StatisticsBookingUser from '../pages/statistics/booking/user'
import StatisticsDeviceBooking from '../pages/statistics/device/booking'
import StatisticsDevicePayment from '../pages/statistics/device/payment'
import StatisticsPlaceViewDay from '../pages/statistics/place/view/day'

import {Setting} from '../pages/setting'
import {SettingHoliday} from '../pages/setting/holiday'
import {SettingAlimtalk} from '../pages/setting/alimtalk'
import {UserEdit} from '../pages/user/Edit'
import {BookingGenreTag} from '../pages/booking/genre'
import {MarketingInterview} from '../pages/marketing/interview'
import {MarketingInterviewEditor} from '../pages/marketing/interview/Editor'
import {MarketingInterviewAdd} from '../pages/marketing/interview/Add'
import {FeedMonitoringSetting} from '../pages/feed/monitoring/Setting'
import {UserPlaceFeedbackList} from '../pages/feed/monitoring/UserPlaceFeedbackList'
import {GuestPortfolioGrey} from '../pages/feed/monitoring/GuestPortfolioGrey'
import {UserGuestFeedList} from '../pages/feed/monitoring/UserGuestFeedList'
import {UserPlaceFeedGrey} from '../pages/feed/monitoring/UserPlaceFeedGrey'
import {UserPlaceWatchList} from '../pages/feed/monitoring/UserPlaceWatchList'
import {UserPlaceFeedList} from '../pages/feed/monitoring/UserPlaceFeedList'
import {UserPlaceFeedDetail} from '../pages/feed/monitoring/UserPlaceFeedDetail'
import {UserPlaceFeedEdit} from '../pages/feed/monitoring/UserPlaceFeedEdit'
import {MarketingMainBanner} from '../pages/marketing/mainBanner'
import {MarketingMainBannerEditor} from '../pages/marketing/mainBanner/Editor'
import {AdvertiseMainBannerFormAdd} from '../pages/advertise/main/Add'
import {AdvertiseMainBannerAdd} from '../pages/advertise/main/banner/Add'
import {AdvertiseMainBannerDetail} from '../pages/advertise/main/banner/Detail'
import {AdvertiseMainBannerIndex} from '../pages/advertise/main/banner/Index'
import {SettingAgree} from '../pages/setting/agree/Index'
import {SettingAgreeAdd} from '../pages/setting/agree/Add'
import {SettingAgreeEdit} from '../pages/setting/agree/Edit'
import {CategoryAdvertiseCancel} from '../pages/advertise/CategoryAdvertiseCancel'
import {ImageShow} from '../pages/media/image/show'
import {ImageShowWait} from '../pages/media/image/showWait'
import {MarketingPlaceCommentaryAdd} from '../pages/marketing/commentary/Add'
import {MarketingPlaceCommentary} from '../pages/marketing/commentary/Index'
import {MarketingPlaceCommentaryEditor} from '../pages/marketing/commentary/Editor'
import {CreativeTag} from '../pages/marketing/creative/Index'
import {MarketingMainBannerImageEditor} from '../pages/marketing/mainBannerImage/Editor'
import {MarketingMainBannerImage} from '../pages/marketing/mainBannerImage'
import MarketingCurationEditor from '../pages/marketing/curation/Editor'
import {PayHostReport4} from '../pages/pay/host/Report4'
import {AdvertiseCategoryIsFreeAdd} from '../pages/advertise/category/place/Add'
import {GreyHostPlace} from '../pages/grey/host/indexPlace'
import {GreyHostAnswer} from '../pages/grey/host/indexAnswer'
import {GreyHostMessage} from '../pages/grey/host/indexMessage'
import {GreyHostHostChange} from '../pages/grey/host/indexHostChange'
import {GreyHostBookingCancel} from '../pages/grey/host/indexBookingCancel'
import {GreyHostAutoMessage} from '../pages/grey/host/indexAutoMessage'
import {GreyGuestPlace} from '../pages/grey/guest/indexPlaceQna'
import {GreyGuestBooking} from '../pages/grey/guest/indexBooking'
import {GreyGuestMessage} from '../pages/grey/guest/indexMessage'
import {GreyGuestUserChange} from '../pages/grey/guest/indexUserChange'
import {GreyGuestBookingCancel} from '../pages/grey/guest/indexBookingCancel'
import {MarketingBtoBHostBannerEditor} from '../pages/marketing/btob/EditorHost'
import {MarketingBtoBHostBanner} from '../pages/marketing/btob/indexHost'
import {MarketingBtoBGuestBannerEditor} from '../pages/marketing/btob/EditorGuest'
import {MarketingBtoBGuestBanner} from '../pages/marketing/btob/indexGuest'
import {AdvertiseBToBGuestBannerAdd} from '../pages/advertise/guest/banner/Add'
import {AdvertiseBToBGuestBannerIndex} from '../pages/advertise/guest/banner/Index'
import {AdvertiseBToBGuestBannerDetail} from '../pages/advertise/guest/banner/Detail'
import {AdvertiseBToBGuestBannerEditor} from '../pages/advertise/guest/banner/Editor'
import {AdvertiseBToBHostBannerAdd} from '../pages/advertise/host/banner/Add'
import {AdvertiseBToBHostBannerIndex} from '../pages/advertise/host/banner/Index'
import {AdvertiseBToBHostBannerDetail} from '../pages/advertise/host/banner/Detail'
import {AdvertiseBToBHostBannerEditor} from '../pages/advertise/host/banner/Editor'
import {PlaceCount} from '../pages/place/PlaceCount'
import Batch from '../pages/batch'
import {PayPoint} from '../pages/pay/point/Index'
import {PayPointWithdraw} from '../pages/pay/point/IndexWithdraw'
import {PayPointWithdrawEdit} from '../pages/pay/point/IndexWithdrawEdit'
import {AdvertiseNetworkPlaceIndexV2} from '../pages/advertise/network/place/IndexV2'
import {PayPointWithdrawCreate} from '../pages/pay/point/IndexWithdrawCreate'
import {AdvertiseNetworkPlusPlaceIndexV2} from '../pages/advertise/network/place/plus/IndexV2'
import {AdvertiseNetworkProPlaceIndexV2} from '../pages/advertise/network/place/pro/IndexV2'
import {UserTrackingDetailV2} from '../pages/user/tracking/DetailV2'
import {IndexMonitoring} from '../pages/monitoring/IndexMonitoring'
import {AdvertiseCategoryPlaceIndexV2} from '../pages/advertise/category/place/IndexV2'
import {MarketingConceptCategory} from '../pages/marketing/conceptCategories/Index'
import {MarketingConceptCategoryEditor} from '../pages/marketing/conceptCategories/Editor'
import {MarketingBanner} from '../pages/marketing/banner/Index'
import {MarketingBannerEditor} from '../pages/marketing/banner/Editor'
import {MarketingBenefits} from '../pages/marketing/benefits/Index'
import {MarketingBenefitsEditor} from '../pages/marketing/benefits/Editor'
import {MarketingHourplaceShorts} from '../pages/marketing/shorts/Index'
import {MarketingHourplaceShortsEditor} from '../pages/marketing/shorts/Editor'
import {MarketingEventNew} from '../pages/marketing/event/IndexV2'
import {MarketingEventsDetailEditor} from '../pages/marketing/event/EditorV2'
import {PayHostReport5} from '../pages/pay/host/Report5'
import {PayHostReport6} from '../pages/pay/host/Report6'
import {BookingPlaceNew} from '../pages/booking/place/indexV2'
import {SettingAlimTalkEditor} from '../pages/setting/alimtalk/Editor'
import {PlaceCountHistory} from '../pages/place/PlaceCountHistory'
import {PlaceCountHistoryH} from '../pages/place/PlaceCountHistoryH'
import {MediaImageFeedbackView} from '../pages/media/image/feedback/indexView'
import {PopBillMemberList} from '../pages/pay/host/cash/PopBillMemberList'
import {PlaceCategoryList} from '../pages/place/category/List'
import {PlaceCategoryGroup} from '../pages/place/category/Group'
import {PlaceFilterRegions} from '../pages/place/filter/Regions'
import {PlaceFilterDistricts} from '../pages/place/filter/Districts'

// import {MarketingCuration} from '../pages/marketing/curation'
// import {MarketingMagazineEditor} from '../pages/marketing/magazine/Editor'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const user: any = useSelector<RootState>(({auth}) => auth.user)

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard/revenue' component={Revenue} />
        <Route path='/dashboard' component={Dashboard} />
        <Route path='/user/tracking/:user_id' component={UserTrackingDetailV2} />
        <Route path='/user/tracking' component={UserTracking} />
        <Route path='/user/list' component={UserList} />
        <Route path='/user/group/:group_id' component={UserGroupDetail} />
        <Route path='/user/group' component={UserGroup} />
        <Route path='/user/:user_id/edit' component={UserEdit} />
        <Route path='/user/:user_id' component={UserDetail} />
        <Route path='/team/dashboard' component={TeamDashboard} />
        <Route path='/team/list' component={TeamList} />
        <Route path='/team/:team_id' component={TeamDetail} />
        <Route path='/booking/today' component={BookingToday} />
        <Route path='/booking/refund' component={BookingRefund} />
        <Route path='/booking/place/new' component={BookingPlaceNew} />
        <Route path='/booking/place/:booking_id/edit/refund' component={BookingPlaceEditRefund} />
        <Route path='/booking/place/:booking_id/edit/info' component={BookingPlaceEditInfo} />
        <Route path='/booking/place/:booking_id/edit/time' component={BookingPlaceEditTime} />
        <Route path='/booking/place/:booking_id/additional' component={BookingPlaceAdditional} />
        <Route path='/booking/place/:booking_id/cancel' component={BookingPlaceCancel} />
        <Route path='/booking/place/:booking_id/payment' component={BookingPlacePayment} />
        <Route path='/booking/place/:booking_id/team/:team_id/payment' component={BookingPlaceTeamPayment} />
        <Route path='/booking/place/:booking_id' component={BookingPlaceDetail} />
        <Route path='/booking/place' component={BookingPlace} />
        <Route path='/booking/feedback' component={BookingFeedback} />
        <Route path='/booking/project' component={BookingProject} />
        <Route path='/booking/message/:booking_id' component={BookingMessageDetail} />
        <Route path='/booking/message' component={BookingMessage} />
        <Route path='/booking/genre' component={BookingGenreTag} />
        <Route path='/advertise/dashboard' component={AdvertiseDashboard} />
        <Route path='/advertise/network/schedule/add' component={AdvertiseNetworkScheduleAdd} />
        <Route path='/advertise/network/schedule' component={AdvertiseNetworkScheduleIndex} />
        <Route path='/advertise/network/:id/schedule' component={AdvertiseNetworkScheduleEdit} />
        <Route path='/advertise/network/pro/:id/place' component={AdvertiseNetworkProPlaceDetail} />
        <Route path='/advertise/network/pro/place/add' component={AdvertiseNetworkProPlaceAdd} />
        <Route path='/advertise/network/pro/place/old' component={AdvertiseNetworkProPlaceIndex} />
        <Route path='/advertise/network/pro/place' component={AdvertiseNetworkProPlaceIndexV2} />
        <Route path='/advertise/network/plus/:id/place/add' component={AdvertiseNetworkPlusPlaceAdd} />
        <Route path='/advertise/network/plus/:id/place' component={AdvertiseNetworkPlusPlaceDetail} />
        <Route path='/advertise/network/plus/place/old' component={AdvertiseNetworkPlusPlaceIndex} />
        <Route path='/advertise/network/plus/place' component={AdvertiseNetworkPlusPlaceIndexV2} />
        <Route path='/advertise/network/:id/place/add' component={AdvertiseNetworkPlaceAdd} />
        <Route path='/advertise/network/:id/place' component={AdvertiseNetworkPlaceDetail} />
        <Route path='/advertise/network/place/old' component={AdvertiseNetworkPlaceIndex} />
        <Route path='/advertise/network/place' component={AdvertiseNetworkPlaceIndexV2} />
        <Route path='/advertise/category/:adv_start/:adv_end/place/add' component={AdvertiseCategoryIsFreeAdd} />
        <Route path='/advertise/category/:id/place' component={AdvertiseCategoryPlaceDetail} />
        <Route path='/advertise/category/place/thumbnail' component={AdvertiseCategoryPlaceThumbnail} />
        <Route path='/advertise/category/place/old' component={AdvertiseCategoryPlaceIndex} />
        <Route path='/advertise/category/place' component={AdvertiseCategoryPlaceIndexV2} />
        <Route path='/advertise/cancel/category' component={CategoryAdvertiseCancel} />
        <Route path='/advertise/cancel' component={AdvertiseCancel} />
        <Route path='/advertise/:adv_cancel_group_id/refund' component={AdvertiseRefund} />
        <Route path='/advertise/main/banner/form' component={AdvertiseMainBannerFormAdd} />
        <Route path='/advertise/main/banner/add' component={AdvertiseMainBannerAdd} />
        <Route path='/advertise/main/banner/:id' component={AdvertiseMainBannerDetail} />
        <Route path='/advertise/main/banner' component={AdvertiseMainBannerIndex} />
        <Route path='/advertise/btob/banner/guest/add' component={AdvertiseBToBGuestBannerAdd} />
        <Route path='/advertise/btob/banner/guest/editor/:id' component={AdvertiseBToBGuestBannerEditor} />
        <Route path='/advertise/btob/banner/guest/:id' component={AdvertiseBToBGuestBannerDetail} />
        <Route path='/advertise/btob/banner/guest' component={AdvertiseBToBGuestBannerIndex} />
        <Route path='/advertise/btob/banner/host/add' component={AdvertiseBToBHostBannerAdd} />
        <Route path='/advertise/btob/banner/host/editor/:id' component={AdvertiseBToBHostBannerEditor} />
        <Route path='/advertise/btob/banner/host/:id' component={AdvertiseBToBHostBannerDetail} />
        <Route path='/advertise/btob/banner/host' component={AdvertiseBToBHostBannerIndex} />
        <Route path='/place/count/history/h' component={PlaceCountHistoryH} />
        <Route path='/place/count/history' component={PlaceCountHistory} />
        <Route path='/place/filter/regions' component={PlaceFilterRegions} />
        <Route path='/place/filter/districts' component={PlaceFilterDistricts} />
        <Route path='/place/category/filter/group' component={PlaceCategoryGroup} />
        <Route path='/place/category/list' component={PlaceCategoryList} />
        <Route path='/place/category' component={PlaceCategory} />
        <Route path='/place/search_tag' component={PlaceSearchTag} />
        <Route path='/place/count' component={PlaceCount} />
        <Route path='/place/:place_id/price/edit' component={PriceEdit} />
        <Route path='/place/:place_id/booking' component={PlaceBooking} />
        <Route path='/place/:place_id/edit' component={PlaceEdit} />
        <Route path='/place/:place_id' component={PlaceDetail} />
        <Route path='/place' component={Place} />
        <Route path='/media/image/feedback/view' component={MediaImageFeedbackView} />
        <Route path='/media/image/feedback' component={MediaImageFeedback} />
        <Route path='/media/image/place' component={MediaImagePlace} />
        <Route path='/media/image/wait/:id' component={ImageShowWait} />
        <Route path='/media/image/:id' component={ImageShow} />
        <Route path='/media/video/place' component={MediaVideoPlace} />
        <Route path='/grey/host/place' component={GreyHostPlace} />
        <Route path='/grey/host/answer' component={GreyHostAnswer} />
        <Route path='/grey/host/message' component={GreyHostMessage} />
        <Route path='/grey/host/hostchange' component={GreyHostHostChange} />
        <Route path='/grey/host/bookingcancel' component={GreyHostBookingCancel} />
        <Route path='/grey/host/automessage' component={GreyHostAutoMessage} />
        <Route path='/grey/host' component={GreyHost} />
        <Route path='/grey/guest/place/qna' component={GreyGuestPlace} />
        <Route path='/grey/guest/booking' component={GreyGuestBooking} />
        <Route path='/grey/guest/message' component={GreyGuestMessage} />
        <Route path='/grey/guest/userchange' component={GreyGuestUserChange} />
        <Route path='/grey/guest/bookingcancel' component={GreyGuestBookingCancel} />
        <Route path='/grey/guest' component={GreyGuest} />
        <Route path='/grey' component={Grey} />
        <Route path='/feed/setting' component={FeedMonitoringSetting} />
        <Route path='/feed/place/grey' component={UserPlaceFeedGrey} />
        <Route path='/feed/feedback' component={UserPlaceFeedbackList} />
        <Route path='/feed/watch' component={UserPlaceWatchList} />
        <Route path='/feed/portfolio/grey' component={GuestPortfolioGrey} />
        <Route path='/feed/portfolio' component={UserGuestFeedList} />
        <Route path='/feed/:feed_id/edit' component={UserPlaceFeedEdit} />
        <Route path='/feed/:feed_id' component={UserPlaceFeedDetail} />
        <Route path='/feed' component={UserPlaceFeedList} />
        <Route path='/search/keyword' component={SearchKeyword} />
        <Route path='/builder' component={BuilderPageWrapper} />

        <Route path='/marketing/curation/:curation_id' component={MarketingCurationEditor} />
        <Route path='/marketing/curation' component={MarketingCuration} />
        <Route path='/marketing/curation_old' component={MainCategory} />
        <Route path='/marketing/coupon/:coupon_id' component={MarketingCouponDetail} />
        <Route path='/marketing/coupon' component={MarketingCoupon} />
        {/*<Route path='/marketing/magazine/:magazine_id' component={MarketingMagazineEditor} />*/}
        <Route path='/marketing/magazine/:magazine_id' component={MagazineEditor} />
        <Route path='/marketing/magazine' component={MarketingMagazine} />
        <Route path='/marketing/event/new/:marketingEventId/editor' component={MarketingEventsDetailEditor} />
        <Route path='/marketing/event/new' component={MarketingEventNew} />
        <Route path='/marketing/event/:event_id' component={MarketingEventEditor} />
        <Route path='/marketing/event' component={MarketingEvent} />
        <Route path='/marketing/main/banner/image/:main_banner_id' component={MarketingMainBannerImageEditor} />
        <Route path='/marketing/main/banner/image' component={MarketingMainBannerImage} />
        <Route path='/marketing/main/banner/:main_banner_id' component={MarketingMainBannerEditor} />
        <Route path='/marketing/main/banner' component={MarketingMainBanner} />
        <Route path='/marketing/btob/banner/guest/:id' component={MarketingBtoBGuestBannerEditor} />
        <Route path='/marketing/btob/banner/guest' component={MarketingBtoBGuestBanner} />
        <Route path='/marketing/btob/banner/host/:id' component={MarketingBtoBHostBannerEditor} />
        <Route path='/marketing/btob/banner/host' component={MarketingBtoBHostBanner} />
        <Route path='/marketing/interview/add' component={MarketingInterviewAdd} />
        <Route path='/marketing/interview/:interview_id' component={MarketingInterviewEditor} />
        <Route path='/marketing/interview' component={MarketingInterview} />
        <Route path='/marketing/place/commentary/add' component={MarketingPlaceCommentaryAdd} />
        <Route path='/marketing/place/commentary/:place_commentary_id' component={MarketingPlaceCommentaryEditor} />
        <Route path='/marketing/place/commentary' component={MarketingPlaceCommentary} />
        <Route path='/marketing/recommend/category/:recommendcategory_id/editor' component={RecommendCategoryEditor} />
        <Route path='/marketing/recommend/category' component={RecommendCategory} />
        <Route path='/marketing/creative' component={CreativeTag} />
        <Route path='/marketing/concept/category/:conceptCategoryId/editor' component={MarketingConceptCategoryEditor} />
        <Route path='/marketing/concept/category' component={MarketingConceptCategory} />
        <Route path='/marketing/banner/:marketingBannerId/editor' component={MarketingBannerEditor} />
        <Route path='/marketing/banner' component={MarketingBanner} />
        <Route path='/marketing/benefits/:marketingBenefitsId/editor' component={MarketingBenefitsEditor} />
        <Route path='/marketing/benefits' component={MarketingBenefits} />
        <Route path='/marketing/hourplace/shorts/:marketingHourplaceShortsId/editor' component={MarketingHourplaceShortsEditor} />
        <Route path='/marketing/hourplace/shorts' component={MarketingHourplaceShorts} />

        <Route path='/statistics/project/type' component={StatisticsProjectType} />
        <Route path='/statistics/booking/day' component={StatisticsBookingDay} />
        <Route path='/statistics/booking/payment/user' component={StatisticsBookingPaymentUser} />
        <Route path='/statistics/booking/user' component={StatisticsBookingUser} />
        <Route path='/statistics/device/booking' component={StatisticsDeviceBooking} />
        <Route path='/statistics/device/payment' component={StatisticsDevicePayment} />
        <Route path='/statistics/place/view/day' component={StatisticsPlaceViewDay} />
        <Route path='/qna/place' component={QnAPlace} />
        <Route path='/qna' component={QnA} />
        <Route path='/setting/alimtalk/editor' component={SettingAlimTalkEditor} />
        <Route path='/setting/alimtalk' component={SettingAlimtalk} />
        <Route path='/setting/holiday' component={SettingHoliday} />
        <Route path='/setting/agree/add' component={SettingAgreeAdd} />
        <Route path='/setting/agree/:agree_id/edit' component={SettingAgreeEdit} />
        <Route path='/setting/agree' component={SettingAgree} />
        <Route path='/setting' component={Setting} />
        <Route path='/log/server' component={LogServer} />
        <Route path='/log/alimtalk' component={LogAlimtalk} />
        <Route path='/log/kcp' component={LogKCP} />
        <Route path='/monitoring' component={IndexMonitoring} />
        <Route path='/pay/host/cash/view' component={PayHostCashView} key={'PayHostCashView'} />,
        <Route path='/pay/host/popbill/member' component={PopBillMemberList} key={'PopBillMemberList'} />,
        {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1) && [<Route path='/pay/host/report' component={PayHostReport} key={'PayHostReport'} />]}
        {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1 || (user.admin >> 8) % 2 === 1) && [<Route path='/pay/host/report2' component={PayHostReport2} key={'PayHostReport2'} />]}
        {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1 || (user.admin >> 8) % 2 === 1) && [<Route path='/pay/host/report5' component={PayHostReport5} key={'PayHostReport5'} />]}
        {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1 || (user.admin >> 8) % 2 === 1) && [<Route path='/pay/host/report6' component={PayHostReport6} key={'PayHostReport6'} />]}
        {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1 || (user.admin >> 8) % 2 === 1) && [<Route path='/pay/host/report4' component={PayHostReport4} key={'PayHostReport4'} />]}
        {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1 || (user.admin >> 8) % 2 === 1) && [<Route path='/pay/host/report3' component={PayHostReport3} key={'PayHostReport3'} />]}
        {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1 || (user.admin >> 8) % 2 === 1) && [
          <Route path='/pay/point/withdraw/:pointId/create' component={PayPointWithdrawCreate} key={'PayPointWithdrawCreate'} />,
        ]}
        {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1 || (user.admin >> 8) % 2 === 1) && [
          <Route path='/pay/point/withdraw/:payId/edit' component={PayPointWithdrawEdit} key={'PayPointWithdrawEdit'} />,
        ]}
        {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1 || (user.admin >> 8) % 2 === 1) && [<Route path='/pay/point/withdraw' component={PayPointWithdraw} key={'PayPointWithdraw'} />]}
        {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1 || (user.admin >> 8) % 2 === 1) && [<Route path='/pay/point' component={PayPoint} key={'PayPoint'} />]}
        {(user.admin >> 10) % 2 === 1 && [<Route path='/pay/bank/remit' component={PayBankRemit} key={'PayBankRemit'} />]}
        {((user.admin >> 10) % 2 === 1 || (user.admin >> 9) % 2 === 1) && [
          <Route path='/pay/host/cash' component={PayHostCash} key={'PayHostCash'} />,
          <Route path='/pay/cash/:cash_id/edit' component={PayCashHostEdit} key={'PayCashHostEdit'} />,
          <Route path='/pay/host' component={PayHost} key={'PayHost'} />,
          <Route path='/pay/booking/:booking_id/edit' component={PayHostEdit} key={'PayHostEdit'} />,
          <Route path='/pay/bank' component={PayBank} key={'PayBank'} />,
        ]}
        {process.env.REACT_APP_DEBUG === 'true' && [<Route path='/batch' component={Batch} key={'Batch'} />]}
        <Redirect from='/auth' to='/dashboard' />
        <Redirect from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
